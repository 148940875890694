import "./TextInput.css";
import React from "react";

export const TextInput = ({ label, placeholder, inputValue, setInputValue, invalid = false }) => {
    return (
        <div className="text-input-box">
            <label htmlFor={label} className="text-input-label-wrapper">{label}</label>
            <input 
                id={label}
                className={`input ${invalid ? "invalid" : ""}`} 
                placeholder={placeholder}
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                autoComplete="off"
            />
        </div>
    );
};