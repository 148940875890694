import "./TextButton.css";
import PropTypes from "prop-types";
import React from "react";

export const TextButton = ({ onClick = () => {}, children, disabled = false }) => {
    return (
        <button onClick={onClick} disabled={disabled} className="text-button">
            <div className="button-text-wrapper">{children}</div>
        </button>
    );
};

TextButton.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.any,
    disabled: PropTypes.bool,
};