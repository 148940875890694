import "./PlaylistTracked.css";
import { PlaylistCard } from "../../Cards/PlaylistCard/PlaylistCard";
import React from "react";
import { CloseButton } from "../../Buttons/CloseButton/CloseButton";

export const PlaylistTracked = ({ playlistInfo, onClose = () => { } }) => {
    return (
        <div className="playlist-tracked">
            <div className="top-section">
                <p className="title">
                    Your playlist is<span className="text-accent"> #{playlistInfo.playlist_rank} </span>on the leaderboard!
                </p>
                <CloseButton onClick={onClose} />
            </div>
            <PlaylistCard playlistInfo={playlistInfo.playlist}/> 
            <div className="info">
                <p className="info-text-wrapper">
                    Add<span className="text-accent"> MV tracks </span>to your playlist to kick it to the top
                </p>
                <p className="p">
                    The number of followers and MV Tracks in the playlist is updated once a day - so the position of your playlist
                    will change the next day if you have made changes to it
                </p>
            </div>
        </div>
    );
};