import "./Loader.css";
import React from "react";
import { ReactComponent as BlocksShuffleSpinner } from "../../../assets/Icons/BlocksShuffleSpinner.svg";

export const Loader = ({ width = "32", height = "32", box = false }) => {
    return (
        <>
            {box ? (
                <div className="loader-box">
                    <BlocksShuffleSpinner className="loader-icon" height={height} width={width} />
                </div>
            ) : (
                <BlocksShuffleSpinner className="loader-icon" height={height} width={width} />
            )}
        </>
    );
};