import "./PopupMenu.css";
import React from "react";

export const PopupMenu = ({ items }) => {
    return (
        <div className="popup-menu">
            <div className="item">
                {items.map((item) =>
                    <p key={item.title} className="item-text" onClick={item.onClick}>
                        {item.title}
                    </p>
                )}
            </div>
        </div>
    );
};