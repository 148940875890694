import "./PlaylistCard.css";
import React from "react";
import { ICONS, Icon } from "../../Icons/Icon";
import { nFormatter, pluralize } from "../../../tools";

export const PlaylistCard = ({ playlistInfo }) => {
    return (
        <div className="playlist-card" onClick={() => window.open(playlistInfo.platform_url, '_blank', 'noopener,noreferrer')}>
            <div className="playlist-info">
                <img className="cover" alt="Playlist cover" src={playlistInfo.image_url} />
                <div className="data">
                    <div className="text-wrapper">{playlistInfo.name}</div>
                    <div className="stats">
                        <div className="left-section">
                            <div className="div">{nFormatter(playlistInfo.follower_count, 0)} {pluralize(nFormatter(playlistInfo.follower_count, 1), "follower")}</div>
                            {playlistInfo.sponsored ? (
                                <div className="div sponsored">sponsored</div>
                            ) : (
                                <div className="div">{playlistInfo.mv_track_count} MV {pluralize(playlistInfo.mv_track_count, "track")}</div>
                            )}
                        </div>
                        {playlistInfo.platform === "spotify" &&
                            <Icon iconType={ICONS.SpotifyIcon} width="16" height="16" />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};