import "./Switch.css";
import React, { useState } from "react";

export const Switch = ({ options, defaultOption }) => {
    const [activeOption, setActiveOption] = useState(defaultOption);

    const handleSwitch = (option) => {
        setActiveOption(option.name);
        option.onClick();
    };

    return (
        <div className="switch">
            {options.map((option) =>
                <div
                    key={option.name}
                    className={`option ${activeOption === option.name ? "active" : ""}`}
                    onClick={() => handleSwitch(option)}
                >
                    <div className="text-wrapper">{option.name}</div>
                </div>
            )}
        </div>
    );
};