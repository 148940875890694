import React from "react";
import { ReactComponent as MVBoxIcon } from "../../assets/Icons/MVBoxIcon.svg";
import { ReactComponent as SpotifyIcon } from "../../assets/Icons/SpotifyIcon.svg";
import { ReactComponent as DotsThreeOutline } from "../../assets/Icons/DotsThreeOutline.svg";
import { ReactComponent as TwitterIcon } from "../../assets/Icons/TwitterIcon.svg";
import { ReactComponent as InstagramIcon } from "../../assets/Icons/InstagramIcon.svg";
import { ReactComponent as FacebookIcon } from "../../assets/Icons/FacebookIcon.svg";
import { ReactComponent as X } from "../../assets/Icons/X.svg";

export const ICONS = {
    MVBoxIcon,
    SpotifyIcon,
    DotsThreeOutline,
    TwitterIcon,
    InstagramIcon,
    FacebookIcon,
    X,
};

export const Icon = ({ iconType, color = "white", width = "32", height = "32", onClick = () => {} }) => {
    return React.createElement(iconType, {
        onClick: onClick,
        width,
        height,
        style: { "color": color }
    });
};
