import "./TrackCard.css";
import React from "react";
import { DotsButton } from "../../Buttons/DotsButton/DotsButton";
import Popup from 'reactjs-popup';
import { PopupMenu } from "../PopupMenu/PopupMenu";

export const TrackCard = ({ trackInfo }) => {


    return (
        <div className="track-card">
            <div className="left-section">
                <img className="track-cover" alt="Track cover" src={trackInfo.image_url} />
                <div className="track-data">
                    <div className="title">{trackInfo.title}</div>
                    <div className="artist">{trackInfo.artist_name}</div>
                </div>
            </div>
            <Popup
                trigger={<div className="button-wrapper"><DotsButton /></div>}
                position="right center"
                keepTooltipInside=".desktop-app-layout"
                on="click"
                closeOnDocumentClick
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                contentStyle={{ padding: '0px', border: 'none' }}
                arrow={false}
            >
                <PopupMenu items={[{
                    title: "Open on Spotify",
                    onClick: () => window.open(trackInfo.sources[0].platform_url, '_blank', 'noopener,noreferrer')
                }]}/>
            </Popup>
        </div>
    );
};