import "./InfoCard.css";
import React from "react";

export const InfoCard = ({ title, content }) => {
    return (
        <div className="info-card">
            <p className="title">{title}</p>
            <p className="content">{content}</p>
        </div>
    );
};