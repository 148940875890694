import "./DotsButton.css";
import { ICONS, Icon } from "../../Icons/Icon";
import React from "react";

export const DotsButton = ({ onClick = () => {}, width = "32", height = "32", ref }) => {
    return (
        <button ref={ref} onClick={onClick} style={{ width: width + "px", height: height + "px" }} className="dots-button">
            <Icon iconType={ICONS.DotsThreeOutline} width={width} height={height} />
        </button>
    );
};