import "./NewMember.css";
import React from "react";
import { CloseButton } from "../../Buttons/CloseButton/CloseButton";

export const NewMember = ({ onClose = () => { } }) => {
    return (
        <div className="joined-overlay">
            <div className="top-section">
                <p className="title">
                    You are now a member of<span className="accent"> MV Box!</span>
                </p>
                <CloseButton onClick={onClose} />
            </div>
            <div className="info">
                <p className="text-wrapper">
                    As an active<span className="accent"> MV Box </span>member, you will receive the top playlists after each snapshot. If you are an artist or curator we will contact you with special offers!
                </p>
                <p className="text-wrapper">
                    Thank you for joining the<span className="accent"> MV Box </span>network!
                </p>
            </div>
        </div>
    );
};